import React, { useEffect, useState } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Hero(props) {
  const [isDesktop, setIsDesktop] = useState(false);

  // Check if we're running in the client-side (browser) context
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsDesktop(window.innerWidth > 768); // Adjust the width as needed for your definition of "desktop."
    }
  }, []);

  return (
    <div className={`hero-container ${isDesktop ? 'desktop-video' : 'mobile-image'}`} style={{ backgroundImage: `url(${props.banner})`}}>
      <div className="container">
      {/* <h5 className="hero-container-pretitle">{props.pretitle}</h5> */}
      <h1 className="hero-container-title">{props.title} <span>Solutions</span></h1>
      <p className="hero-container-description">{props.description}</p>
      <AnchorLink className="button button--large button--primary" to="#contact" title="Request a quote">
          Get a quick quote
        </AnchorLink>
      </div>
      {isDesktop && (
        <div className="video-container">
          <video autoPlay muted loop poster={props.poster}>
            <source src={props.video} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
}
